import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const RelatedPosts = ({ post }) => {
    const url = `/${post.slug}`
    const readingTime = readingTimeHelper(post)
    
    return (
      <div className="col-md-4 post-wrap clearfix">
                    <Link to={url}>
                        <div className="featured-image-wrap">
                            <div className="featured-image">
                                {post.feature_image && (
                                    <div
                                        className="featured-image"
                                        style={{
                                            backgroundImage: `url(${post.feature_image})`,
                                        }}
                                    ></div>
                                )}
                            </div>
                        </div>
                    </Link>

                    <div className="content-wrap">
                      <h2 className="title h5"><Link to={'/' + post.slug}>{post.title}</Link></h2>
                      <div className="date" title={post.published_at}>
                              {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "2-digit",
                              }).format(new Date(post.published_at))}
                          </div>
                    </div>
      </div>
    );
}

RelatedPosts .propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default RelatedPosts
