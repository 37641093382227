import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout, ShareLinks } from '../components/common'
import { MetaData } from '../components/common/meta'
import RelatedPosts from '../components/common/RelatedPosts'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.ghostPost
    const posts = data.allGhostPost.edges

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>

            <Layout>
                <div className="container">

<div className="single-post-wrap">
            <div className="post-head d-flex has-image">
            { post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ post.feature_image } alt={ post.title } />
                            </figure> : null }
                <div className="header-inner align-self-center">

                    <div className="primary-tag">
                        <a href={ '/tag/' + post.tags[0].slug }>{ post.tags[0].name}</a>
                    </div>

                    <h1 className="title">{ post.title }</h1>
                    <div className="post-meta">
                        <a href={ '/author/' + post.authors[0].slug } className="author"><span>By</span> { post.authors[0].name}</a>
                        <div className="date" title={post.published_at}>
                                              { new Intl.DateTimeFormat('en-US', {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                              }).format(new Date(post.published_at))}
                                  </div>
                    </div>
                </div>
            </div>
            <div className="post-content m-auto">

            <section className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />

                { post.tags ? (
                    <div className="tag-wrap">
                    <Link className="post-tag-nav-item" to={'/tag/' + post.tags[0].slug} >{post.tags[0].name}</Link>
                    </div>
                ) : null }

                <ShareLinks post={post}></ShareLinks>
                


            </div>
        </div>
        <div class="related-post-wrap">
                    <div class="section-title">
                        <span>More in {post.primary_tag.name}</span>
                    </div>
                    <div class="row">
                        {posts.map(({ node }) => (
                            <RelatedPosts key={node.id} post={node} />
                        ))}
                    </div>
                </div>
                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!, $limit: Int!, $primary_tag: String!) {
        ghostPost(
            slug: { eq: $slug }
        ) {
            ...GhostPostFields
        }
        allGhostPost(
            filter: {slug: {nin: [$slug] }, primary_tag: {slug: {eq: $primary_tag}}},
            limit: $limit
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
